import React, { PropsWithChildren } from "react";

export interface CTABannerProps {
  imgSrc: string;
}

export default (props: PropsWithChildren<CTABannerProps>) => {
  const { children, imgSrc } = props;

  return (
    <div
      id="cta-banner"
      className="cta-banner"
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      {children}
    </div>
  );
};
