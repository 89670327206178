import Button from "components/Button";
import CTABanner from "components/CTABanner";
import SEO from "components/SEO";
import { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "layout";
import React from "react";
import { BasePageContext, TemplatePageProps } from "utils/types";

const aboutBannerImg = require("images/hillary-taylor-about-banner.jpg").default;
const htAboutImg = require("images/hillary-taylor-about.jpg").default;

export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;

  return (
    <Layout appData={appData}>
      <div
        className="container--large spaced-100"
        style={{ marginInline: "auto", paddingInline: "1rem" }}
      >
        <div className="meet-hillary">
          <div className="content">
            <p>
              Hillary loves the process of creating beautiful surroundings for
              her clients and their families. Her spaces always begin with a
              strong founding concept – created alongside every client. That
              unique and unwavering direction results in a home filled with
              meaning and lasting pieces.
            </p>

            <p>
              Hillary’s personal style is marked by neo-traditional elements
              informed by her time living in California, the East Coast, and
              Paris.
            </p>
            <p>
              Having grown up on the peninsula south of San Francisco, Hillary
              learned the art of designing the home environment from her mother.
              She graduated cum laude from Princeton University, worked for an
              investment banking firm, and received a J.D. from J. Reuben Clark
              Law School before founding her business, Hillary W Taylor
              Interiors in 2003.
            </p>
            <p>
              When Hillary is not in the design studio, one might find her
              digging in the dirt, on the running trail, or cheering on one of
              her four children at their sports games, all while awaiting her
              next plane ride to adventure.
            </p>
          </div>
          <div className="image">
            <img src={htAboutImg} alt="Hillary Taylor" />
          </div>
        </div>
      </div>
      <CTABanner imgSrc={aboutBannerImg}>
        <Button link="/contact" classes="blue">
          GET IN TOUCH
        </Button>
      </CTABanner>
    </Layout>
  );
};

export const Head: HeadFC = () => (
  <SEO
    title="About"
    ogMeta={{
      description:
        "Hillary loves the process of creating beautiful surroundings for her clients and their families.",
    }}
  ></SEO>
);
